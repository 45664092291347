import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Colors,
  Spinner,
  Button,
  ButtonGroup,
  Tooltip,
  Dialog,
  DialogBody,
  DialogFooter,
  Text,
} from '@blueprintjs/core';
import styled from 'styled-components';
import { showToast } from '../Util/Toaster.js';
import { HiBadgeCheck } from 'react-icons/hi';
import ErrorState from './ErrorStateView.js';

import { UserContext } from '../Contexts/UserContext.js';
import NetworkSettings from '../Hooks/NetworkSettings.js';
import StreamingMarkdown from '../Components/StreamingMarkdown.js';
import ResultTable from '../Components/ResultTable.js';
import { parseDeliverableContents } from '../Components/DeliverableParser.js';
import { snakeToCamel, camelToSnake } from '../Util/CaseConvert.js';
import { HTTPError } from '../Util/Errors.js';

import {
  MDXEditor,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  CreateLink,
  InsertImage,
  InsertTable,
  ListsToggle,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  tablePlugin,
  imagePlugin,
  linkPlugin,
  linkDialogPlugin,
  markdownShortcutPlugin,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
} from '@mdxeditor/editor';

const API_URL = NetworkSettings.SERVER_URL;

const DeliverableViewContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerBody = styled.div``;

const ImageContainer = styled.div`
  width: 100%;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const DeliverableDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeliverableDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 18px 24px;
  width: 100%;
  background-color: ${Colors.WHITE};
`;

const DeliverableDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;

const DeliverableDetailsButtonGroup = styled(ButtonGroup)`
  width: 100%;
  gap: 12px;
`;

const DeliverableDetailsTitle = styled.h2`
  font-size: 1.5rem;
  color: ${Colors.GREEN3};
`;

const DeliverableDetailsDate = styled.div`
  font-size: 0.9rem;
  color: #888;
  font-weight: 600;
`;

const DeliverableDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
`;

const DeliverableDetailsLongAnswer = styled.div`
  font-size: 1rem;
  color: #666;
  border-radius: 4px;
  padding: 12px;
  height: auto;
  overflow: visible;
  flex: 0 0 auto;
`;

const TableContainer = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 12px 0;
`;

const StyledMDXEditor = styled(MDXEditor)`
  h2[id^='radix-:'] {
    display: none;
  }
`;

const EditContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
`;

const DualButtonDialogFooter = styled(DialogFooter)`
  & .bp5-dialog-footer-main-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
`;

const AdminDeliverableView = () => {
  const { deliverableID } = useParams();
  const { user, organizationId } = useContext(UserContext);

  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [parsedContents, setParsedContents] = useState({});
  const [isEditing, setIsEditing] = useState(window.location.pathname.includes('/edit'));
  const initialRenderRef = useRef(true);
  const [isUserEdit, setIsUserEdit] = useState(false);
  const [longAnswer, setLongAnswer] = useState('');
  const [initialLongAnswer, setInitialLongAnswer] = useState('');
  const longAnswerChanged = isUserEdit && longAnswer !== initialLongAnswer;

  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);

  const [deliverableData, setDeliverableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const targetRef = useRef(null);

  const navigate = useNavigate();

  const hasOrgAccess =
    NetworkSettings.ALLOWED_WOS_ORGS && organizationId && NetworkSettings.ALLOWED_WOS_ORGS === organizationId;
  const isNonProduction =
    NetworkSettings.SERVER_URL.includes('localhost') || NetworkSettings.SERVER_URL.includes('staging');

  useEffect(() => {
    const fetchDeliverableData = async () => {
      if (!deliverableID) {
        setError(new HTTPError(400, 'Missing deliverable ID'));
        setLoading(false);
        return;
      }
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${API_URL}/get-deliverable/${deliverableID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new HTTPError(response.status, response.statusText);
        }

        const data = await response.json();
        const parsedData = snakeToCamel(data);
        const contents = parseDeliverableContents(snakeToCamel(data));

        setDeliverableData(parsedData);
        setParsedContents(contents);

        const newLongAnswer = contents.textMarkdown || '';
        setLongAnswer(newLongAnswer);
        setInitialLongAnswer(newLongAnswer);
      } catch (error) {
        if (error instanceof HTTPError) {
          setError(error);
        } else {
          setError(new HTTPError(500, 'An unexpected error occurred'));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDeliverableData();
  }, [deliverableID]);

  const handleEdit = async () => {
    if (isEditing && longAnswerChanged) {
      const textComponent = deliverableData.data?.components?.find((c) => c.type === 'text');
      const tableComponent = deliverableData.data?.components?.find((c) => c.type === 'table');

      if (!textComponent || !tableComponent) {
        showToast({
          message: 'Missing required components',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        return;
      }

      const requestBody = {
        deliverable_id: deliverableID,
        name: deliverableData.data.metadata.name,
        components: [
          {
            component_id: textComponent.componentId,
            type: 'text',
            body: longAnswer,
            position: textComponent.position,
          },
          {
            component_id: tableComponent.componentId,
            type: 'table',
            body: parsedContents.tableString || '',
            position: tableComponent.position,
          },
        ],
      };
      try {
        const response = await fetch(`${API_URL}/save-deliverable/`, {
          method: 'POST',
          body: JSON.stringify(camelToSnake(requestBody)),
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok for save deliverable`);
        }
        const data = await response.json();

        showToast({
          message: `Deliverable saved successfully`,
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      } catch (error) {
        console.error('Error saving deliverable:', error);
        showToast({
          message: `Failed to save deliverable: ${error.message}`,
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      }
    }

    const newIsEditing = !isEditing;
    setIsEditing(newIsEditing);

    const newPath = newIsEditing ? `/admin/deliverables/${deliverableID}/edit` : `/admin/deliverables/${deliverableID}`;
    navigate(newPath, { replace: true });
  };

  const handleCopyShareLink = () => {
    const shareUrl = `${window.location.origin}/admin/deliverables/${deliverableID}`;
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        showToast({
          message: 'Share link copied to clipboard',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
        setTimeout(() => {
          setCopiedMessageId(null);
        }, 1500);
      })
      .catch((err) => {
        console.error('Failed to copy message:', err);
      });
  };

  const handleApprove = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/approve-deliverable/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          deliverable_id: deliverableID,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to approve deliverable');
      }

      const data = await response.json();
      if (data.status === 'success') {
        setDeliverableData((prevData) => ({
          ...prevData,
          data: {
            ...prevData.data,
            metadata: {
              ...prevData.data.metadata,
              isApproved: true,
            },
          },
        }));

        showToast({
          message: 'Deliverable approved successfully',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      }
    } catch (error) {
      console.error('Error approving deliverable:', error);
      setError(error.message);
      showToast({
        message: 'Failed to approve deliverable',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) {
    switch (error.status) {
      case 400:
        return <ErrorState error="Bad Request: The server couldn't understand the request." />;
      case 404:
        return (
          <ErrorState
            error="Deliverable not found: The requested deliverable doesn't exist."
            action={() => navigate(`/admin/${deliverableID}`)}
            buttonText="Back to deliverables"
          />
        );
      case 500:
        return <ErrorState error="Internal Server Error: Something went wrong on our end." />;
      default:
        return <ErrorState error={`An error occurred: ${error.message}`} />;
    }
  }

  return (
    <DeliverableViewContainer className="deliverable-view-container">
      <ContainerHeader className="container-header">
        {!user && (
          <ImageContainer className="image-container">
            <a href="https://sleuthinsights.com/" target="_blank" rel="noreferrer">
              <img src="/img/logos/sleuth_logo.png" alt="Sleuth Insights" />
            </a>
          </ImageContainer>
        )}

        <DeliverableDetailsButtonGroup className="deliverable-details-button-group">
          <Button
            intent="primary"
            icon="circle-arrow-left"
            text="Back"
            onClick={() => navigate(`/admin/${deliverableID}`)}
          />

          <Button intent="success" icon="share" text="Share" onClick={handleCopyShareLink} />

          <Button
            intent="success"
            icon={isEditing ? (longAnswerChanged ? 'floppy-disk' : 'eye-open') : 'edit'}
            text={isEditing ? (longAnswerChanged ? 'Save' : 'View') : 'Edit'}
            onClick={handleEdit}
            disabled={!deliverableID}
          />

          <Button
            intent="success"
            icon="tick"
            text={deliverableData?.data?.metadata?.isApproved ? 'Approved' : 'Approve'}
            onClick={() => setIsApproveDialogOpen(true)}
            disabled={!deliverableData?.data?.metadata?.isComplete || deliverableData?.data?.metadata?.isApproved}
          />
          <Dialog isOpen={isApproveDialogOpen} onClose={() => setIsApproveDialogOpen(false)}>
            <DialogBody>
              <h3>Approve Deliverable</h3>
              <Text>Are you sure you want to approve this deliverable?</Text>
            </DialogBody>
            <DualButtonDialogFooter>
              <Button text="Cancel" onClick={() => setIsApproveDialogOpen(false)} />
              <Button
                intent="primary"
                icon="floppy-disk"
                text="Approve"
                onClick={() => {
                  handleApprove();
                  setIsApproveDialogOpen(false);
                }}
              />
            </DualButtonDialogFooter>
          </Dialog>
        </DeliverableDetailsButtonGroup>
      </ContainerHeader>

      <ContainerBody className="container-body">
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <DeliverableDetailsContainer className="deliverable-details-container">
            <DeliverableDetailsWrapper className="deliverable-details-wrapper" ref={targetRef}>
              <DeliverableDetailsHeader className="deliverable-details-header">
                <DeliverableDetailsTitle className="deliverable-details-title">
                  {deliverableData?.data?.metadata?.name}{' '}
                  {deliverableData?.data?.metadata?.isApproved && <HiBadgeCheck />}
                </DeliverableDetailsTitle>
                <DeliverableDetailsDate className="deliverable-details-date">
                  Last Updated{' '}
                  {deliverableData?.data?.metadata?.updatedAt
                    ? new Date(deliverableData.data.metadata.updatedAt).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      })
                    : 'N/A'}
                </DeliverableDetailsDate>
              </DeliverableDetailsHeader>

              <DeliverableDetailsBody className="deliverable-details-body">
                {parsedContents?.textMarkdown && (
                  <DeliverableDetailsLongAnswer className="deliverable-details-long-answer">
                    {isEditing ? (
                      <EditContainer className="edit-container">
                        <StyledMDXEditor
                          markdown={longAnswer || ''}
                          onChange={(markdown) => {
                            setLongAnswer(markdown);
                            if (initialRenderRef.current) {
                              initialRenderRef.current = false;
                            } else {
                              setIsUserEdit(true);
                            }
                          }}
                          spellCheck={false}
                          trim={false}
                          plugins={[
                            headingsPlugin(),
                            listsPlugin(),
                            quotePlugin(),
                            thematicBreakPlugin(),
                            imagePlugin({
                              imageUploadHandler: undefined,
                              // Simple validation for image URLs
                              validateUrl: (url) => {
                                const isValidImageUrl = url.match(/^https?:\/\/.+\.(jpg|jpeg|png|gif|webp)$/i);
                                return isValidImageUrl !== null;
                              },
                            }),
                            tablePlugin(),
                            linkPlugin(),
                            linkDialogPlugin(),
                            markdownShortcutPlugin(),
                            diffSourcePlugin({ diffMarkdown: initialLongAnswer, viewMode: 'rich-text' }),
                            toolbarPlugin({
                              toolbarClassName: 'editor-toolbar',
                              toolbarContents: () => (
                                <DiffSourceToggleWrapper>
                                  <UndoRedo />
                                  <BoldItalicUnderlineToggles />
                                  <BlockTypeSelect />
                                  <CreateLink />
                                  <InsertImage />
                                  <InsertTable />
                                  <ListsToggle />
                                </DiffSourceToggleWrapper>
                              ),
                            }),
                          ]}
                        />
                      </EditContainer>
                    ) : (
                      <StreamingMarkdown content={longAnswer || ''} />
                    )}
                  </DeliverableDetailsLongAnswer>
                )}

                {parsedContents?.tableString && (
                  <TableContainer className="table-container">
                    <ResultTable
                      tableString={parsedContents.tableString}
                      initialVisibleColumns={[
                        'drug_name',
                        'sponsor',
                        'nct_id',
                        'trial_name',
                        'trial_status',
                        'efficacy_data',
                        'safety_data',
                      ]}
                      limitTableWidth={true}
                    />
                  </TableContainer>
                )}
              </DeliverableDetailsBody>
            </DeliverableDetailsWrapper>
          </DeliverableDetailsContainer>
        )}
      </ContainerBody>
    </DeliverableViewContainer>
  );
};

export default AdminDeliverableView;
