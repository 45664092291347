import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Colors,
  ButtonGroup,
  Spinner,
  Dialog,
  DialogBody,
  DialogFooter,
  Text,
  InputGroup,
} from '@blueprintjs/core';
import styled from 'styled-components';
import html2pdf from 'html2pdf.js';
import { showToast } from '../Util/Toaster.js';
import { snakeToCamel, camelToSnake } from '../Util/CaseConvert.js';
import { HiBadgeCheck } from 'react-icons/hi';

import { UserContext } from '../Contexts/UserContext.js';
import NetworkSettings from '../Hooks/NetworkSettings.js';
import StreamingMarkdown from '../Components/StreamingMarkdown.js';
import ResultTable from '../Components/ResultTable.js';
import { parseDeliverableContents } from '../Components/DeliverableParser.js';

const API_URL = NetworkSettings.SERVER_URL;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const DeliverablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  min-width: 0;
`;

const DeliverablesList = styled.div`
  background-color: ${Colors.LIGHT_GRAY2};
  padding: 18px 24px;
  flex: 0.6;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;

  border-radius: 4px;

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const DeliverableItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;

  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: ${Colors.WHITE};

  min-height: 150px;
  height: auto;
  padding: 12px 16px;

  // Add these properties to prevent shrinking
  flex: 0 0 auto;
  overflow: visible;
`;

const DeliverableTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const DeliverableText = styled.div`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: #666;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DeliverableDateText = styled.div`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: #666;
  display: flex;
  flex-direction: column;
`;

const DeliverableName = styled.div`
  font-weight: 600;
  color: ${Colors.GREEN3};
`;

const DeliverableDate = styled.div`
  color: #888;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.8;
`;

const DeliverableButton = styled(Button)`
  width: 100%;
`;

const DeliverableDetails = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 0;
`;

const DeliverableDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 0;
`;

const DeliverableDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 18px 24px;
  width: 100%;
  background-color: ${Colors.WHITE};

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  min-width: 0;
`;

const DeliverableDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;

const DeliverableDetailsTitle = styled.h2`
  font-size: 1.5rem;
  color: ${Colors.GREEN3};
`;

const DeliverableDetailsDate = styled.div`
  font-size: 0.9rem;
  color: #888;
  font-weight: 600;
`;

const DeliverableDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
  min-width: 0;
`;

const DeliverableDetailsLongAnswer = styled.div`
  font-size: 1rem;
  color: #666;
  border-radius: 4px;
  padding: 12px;
  height: auto; // Allow height to grow with content
  overflow: visible; // Show overflow content
  flex: 0 0 auto; // Don't flex or shrink, just use natural height
`;

const EditableTextArea = styled.textarea`
  font-size: 1rem;
  color: #666;
  white-space: pre-wrap;
  border-radius: 4px;
  padding: 12px;
  height: 100%;
  width: 100%;
  resize: none;
  border: 1px solid #ccc;
  background-color: ${Colors.LIGHT_GRAY5};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const EditContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
`;

const EditColumn = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

const DeliverableDetailsButtonGroup = styled(ButtonGroup)`
  width: 100%;
  gap: 12px;
`;

const TableContainer = styled.div`
  flex: 0 0 auto; // Don't grow or shrink
  width: 100%;
  padding: 12px 0;
`;

const DeleteDialogFooter = styled(DialogFooter)`
  & .bp5-dialog-footer-main-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
`;

const DeliverableLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

// ---------------------------------------------------------------------------------------------------------------------

const AIAgentsRepositoryView = () => {
  const params = useParams();
  const { deliverableID } = params;
  const { organizationId } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [deliverables, setDeliverables] = useState([]);
  const [deliverableContent, setDeliverableContent] = useState({});

  const [selectedDeliverableId, setSelectedDeliverableId] = useState(deliverableID);
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);
  const [copiedMessageId, setCopiedMessageId] = useState(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [parsedContents, setParsedContents] = useState({});

  const initialRenderRef = useRef(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isUserEdit, setIsUserEdit] = useState(false);
  const [longAnswer, setLongAnswer] = useState('');
  const [initialLongAnswer, setInitialLongAnswer] = useState('');
  const longAnswerChanged = isUserEdit && longAnswer !== initialLongAnswer;

  const [nameInput, setNameInput] = useState('');
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const targetRef = useRef(null);

  const navigate = useNavigate();

  // Get deliverables for user for initial load
  useEffect(() => {
    const fetchDeliverables = async () => {
      try {
        const response = await fetch(`${API_URL}/get-user-deliverables/`, {
          credentials: 'include',
        });

        const data = await response.json();
        setDeliverables(snakeToCamel(data.data));
      } catch (error) {
        console.error('Error fetching deliverables:', error);
      }
    };

    fetchDeliverables();
  }, []);

  // Fetch content for each deliverable for initial load
  useEffect(() => {
    const fetchDeliverableContent = async (isInitialLoad = false) => {
      if (isInitialLoad) {
        setLoading(true);
      }
      setError(null);

      try {
        const deliverablePromises = deliverables.map(async (deliverable) => {
          const response = await fetch(`${API_URL}/get-deliverable/${deliverable.deliverableId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          });

          if (!response.ok) {
            throw new Error(`Network response was not ok for deliverable ${deliverable.deliverableId}`);
          }

          const data = await response.json();
          return { [deliverable.deliverableId]: snakeToCamel(data) };
        });

        const results = await Promise.all(deliverablePromises);
        const combinedDeliverables = results.reduce((acc, curr) => ({ ...acc, ...curr }), {});

        setDeliverableContent(combinedDeliverables);
      } catch (error) {
        console.error('Error fetching deliverables:', error);
        setError(error.message);
      } finally {
        if (isInitialLoad) {
          setLoading(false);
        }
      }
    };

    fetchDeliverableContent(true);
  }, [deliverables]);

  // Set the ID immediately from the URL
  useEffect(() => {
    if (deliverableID) {
      setSelectedDeliverableId(deliverableID);
    }
  }, [deliverableID]);

  // Update the content once it's available
  useEffect(() => {
    let mounted = true;

    if (selectedDeliverableId && deliverableContent[selectedDeliverableId]) {
      try {
        const contents = parseDeliverableContents(deliverableContent[selectedDeliverableId]);
        if (mounted) {
          setParsedContents(contents);
          setSelectedDeliverable(deliverableContent[selectedDeliverableId]);

          const newLongAnswer = contents.textMarkdown || '';
          setLongAnswer(newLongAnswer);
          setInitialLongAnswer(newLongAnswer);
        }
      } catch (error) {
        console.error('Error parsing deliverable contents:', error);
        if (mounted) {
          setParsedContents({});
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [selectedDeliverableId, deliverableContent]);

  // Update selected deliverable and navigate to it
  const handleDeliverableSelect = (id) => {
    setSelectedDeliverableId(id);
    setSelectedDeliverable(deliverableContent[id]);
    setLongAnswer(deliverableContent[id]?.data?.components.find((c) => c.type === 'text')?.body);

    navigate(`/ai-agents/repository/${id}`);
  };

  // Reset the isUserEdit flag when selecting a new deliverable
  useEffect(() => {
    if (selectedDeliverableId) {
      setIsUserEdit(false);
      initialRenderRef.current = true;
    }
  }, [selectedDeliverableId]);

  // Handle PDF download
  const handleDownload = async () => {
    if (targetRef.current) {
      const element = targetRef.current;
      const originalStyle = element.style.cssText;

      // Store original graph container styles
      const graphContainer = element.querySelector('.deliverable-details-graph');
      const originalGraphStyle = graphContainer ? graphContainer.style.cssText : '';

      // Temporary styles for PDF generation
      element.style.height = 'auto';
      element.style.maxHeight = 'none';
      element.style.overflow = 'visible';
      element.style.padding = '12px';

      // Adjust spacing
      const headerElements = element.querySelectorAll('.deliverable-details-header, .deliverable-details-body');
      headerElements.forEach((el) => {
        el.style.margin = '0';
        el.style.padding = '0';
        el.style.gap = '8px';
      });

      // Ensure graph captures fully
      if (graphContainer) {
        graphContainer.style.width = '90%';
        graphContainer.style.margin = '12px auto';
        graphContainer.style.maxWidth = '800px';

        const canvas = graphContainer.querySelector('canvas');
        if (canvas) {
          canvas.style.width = '100%';
          canvas.style.maxWidth = '100%';
        }
      }

      const opt = {
        margin: 0,
        filename: `${selectedDeliverable.data.metadata.name || 'deliverable'}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          windowWidth: 794, // 210mm converted to pixels at 96 DPI
          width: 794,
          onclone: function (clonedDoc) {
            const clonedGraph = clonedDoc.querySelector('.deliverable-details-graph');
            if (clonedGraph) {
              clonedGraph.style.width = '90%';
              clonedGraph.style.margin = '12px auto';
              clonedGraph.style.maxWidth = '800px';
            }
          },
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
          compress: true,
          precision: 16,
        },
        pagebreak: {
          mode: 'avoid-all',
        },
      };

      try {
        await html2pdf().set(opt).from(element).save();

        showToast({
          message: 'PDF downloaded successfully',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      } catch (error) {
        // Show error toast
        showToast({
          message: 'Failed to generate PDF',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        console.error('PDF generation error:', error);
      } finally {
        // Only restore styles, not content
        element.style.cssText = originalStyle;
        if (graphContainer) {
          graphContainer.style.cssText = originalGraphStyle;
        }

        // Restore other element styles
        headerElements.forEach((el) => {
          el.style.margin = '';
          el.style.padding = '';
          el.style.gap = '';
        });
      }
    }
  };

  // Copy share link to clipboard
  const handleCopyShareLink = (deliverableID) => {
    navigator.clipboard
      .writeText(`${window.location.origin}/ai-agents/deliverables/${deliverableID}`)
      .then(() => {
        showToast({
          message: 'Share link copied to clipboard',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
        setTimeout(() => {
          setCopiedMessageId(null);
        }, 1500);
      })
      .catch((err) => {
        console.error('Failed to copy message:', err);
      });
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);

    const componentId = selectedDeliverable?.data?.components?.find((c) => c.type === 'table')?.componentId;
    if (!componentId) {
      showToast({
        message: 'No table component found',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
      setLoading(false);
      return;
    }

    try {
      // First refresh the table
      const response = await fetch(`${API_URL}/refresh-table/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          component_id: componentId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok for refresh table`);
      }

      const data = await response.json();

      if (data.data?.error) {
        showToast({
          message: data.data.error,
          intent: 'danger',
          icon: 'help',
          title: 'Erroneous...',
        });
        return;
      }

      if (!data.data?.body) {
        throw new Error('No table data received from server');
      }

      // Parse the body field which contains the table data
      let parsedBody;
      try {
        parsedBody = JSON.parse(data.data.body);
        if (!Array.isArray(parsedBody)) {
          throw new Error('Invalid table data structure');
        }
      } catch (parseError) {
        console.error('Failed to parse table data:', data.data.body);
        throw new Error('Invalid table data received from server');
      }

      const newTableString = JSON.stringify(parsedBody);

      // Update the parsedContents with the new table data
      setParsedContents((prev) => ({
        ...prev,
        tableString: newTableString,
      }));

      // Then save the deliverable with the updated table
      const saveResponse = await fetch(`${API_URL}/save-deliverable/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(
          camelToSnake({
            deliverableId: selectedDeliverableId,
            name: selectedDeliverable.data.metadata.name,
            components: [
              {
                componentId: selectedDeliverable.data.components.find((c) => c.type === 'text').componentId,
                type: 'text',
                body: selectedDeliverable.data.components.find((c) => c.type === 'text').body,
                position: selectedDeliverable.data.components.find((c) => c.type === 'text').position,
              },
              {
                componentId: componentId,
                type: 'table',
                body: newTableString,
                position: selectedDeliverable.data.components.find((c) => c.type === 'table').position,
                sql_query: data.data.sql_query,
              },
            ],
          }),
        ),
      });

      if (!saveResponse.ok) {
        throw new Error('Failed to save updated deliverable');
      }

      showToast({
        message: 'Table refreshed and saved successfully',
        intent: 'success',
        icon: 'tick',
        title: 'Elementary!',
      });
    } catch (error) {
      console.error('Error refreshing table:', error);
      setError(error.message);
      showToast({
        message: 'Failed to refresh table',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/delete-deliverable/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          deliverable_id: selectedDeliverableId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok for delete deliverable`);
      }

      const data = await response.json();
      if (data.status === 'success') {
        showToast({
          message: 'Deliverable deleted successfully',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      }
      setDeliverables((prevDeliverables) => prevDeliverables.filter((d) => d.deliverableId !== selectedDeliverableId));
      setSelectedDeliverableId(null);
      setSelectedDeliverable(null);
      setParsedContents({});
      setLongAnswer('');
      setInitialLongAnswer('');

      navigate('/ai-agents/repository');
    } catch (error) {
      console.error('Error fetching deliverables:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRename = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/rename-deliverable/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          deliverable_id: selectedDeliverableId,
          name: nameInput,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to rename deliverable');
      }

      // Update deliverables list
      setDeliverables((prevDeliverables) =>
        prevDeliverables.map((d) => (d.deliverableId === selectedDeliverableId ? { ...d, name: nameInput } : d)),
      );

      // Update selected deliverable
      setSelectedDeliverable((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          metadata: {
            ...prev.data.metadata,
            name: nameInput,
          },
        },
      }));

      showToast({
        message: 'Deliverable renamed successfully',
        intent: 'success',
        icon: 'tick',
        title: 'Elementary!',
      });
    } catch (error) {
      console.error('Error renaming deliverable:', error);
      setError(error.message);
    } finally {
      setLoading(false);
      setIsRenameDialogOpen(false);
    }
  };

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Deliverables Repository</PageTitle>
        <PageSubtitle className="page-subtitle">
          Access your completed AI analyses here. You can view, edit, and share finished reports. For in-progress tasks,
          we'll notify you when they're ready.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        {loading ? (
          <Spinner />
        ) : !deliverables?.length ? (
          <div>No deliverables found</div>
        ) : (
          <DeliverablesContainer className="deliverables-container">
            <DeliverablesList className="deliverables-list">
              {deliverables.map((deliverable) => (
                <DeliverableItem key={deliverable.deliverableId} className="deliverable-item">
                  <DeliverableTextContainer>
                    <DeliverableText>
                      <DeliverableName>
                        {deliverable.name} {deliverable.isApproved ? <HiBadgeCheck /> : ''}
                      </DeliverableName>
                    </DeliverableText>
                    <DeliverableDateText>
                      <DeliverableDate>
                        Created{' '}
                        {new Date(deliverable.createdAt).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </DeliverableDate>
                      <DeliverableDate>
                        Last Updated{' '}
                        {new Date(deliverable.updatedAt).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </DeliverableDate>
                    </DeliverableDateText>
                  </DeliverableTextContainer>

                  <DeliverableButton
                    fill
                    small
                    intent="primary"
                    icon={
                      deliverable?.isApproved || deliverable?.needsApproval === false
                        ? selectedDeliverableId === deliverable.deliverableId
                          ? 'eye-on'
                          : 'eye-open'
                        : 'eye-off'
                    }
                    onClick={() => handleDeliverableSelect(deliverable.deliverableId)}
                    disabled={
                      deliverable?.needsApproval === false
                        ? false
                        : selectedDeliverableId === deliverable.deliverableId ||
                          !deliverable?.isApproved ||
                          !deliverable?.isComplete ||
                          deliverable?.needsApproval === true
                    }
                  >
                    {deliverable?.isApproved || deliverable?.needsApproval === false ? (
                      selectedDeliverableId === deliverable.deliverableId ? (
                        'Viewing'
                      ) : (
                        'View'
                      )
                    ) : (
                      <>
                        Preparing... <Spinner size={12} style={{ display: 'inline-block', marginLeft: '4px' }} />
                      </>
                    )}
                  </DeliverableButton>
                </DeliverableItem>
              ))}
            </DeliverablesList>

            <DeliverableDetails className="deliverable-details">
              {selectedDeliverable?.data && (
                <DeliverableDetailsContainer className="deliverable-details-container">
                  <DeliverableDetailsWrapper className="deliverable-details-wrapper" ref={targetRef}>
                    {!selectedDeliverable.data.metadata.isApproved &&
                    selectedDeliverable.data.metadata.needsApproval !== false ? (
                      <DeliverableLoadingContainer>
                        <Spinner size={50} />
                        <Text style={{ marginLeft: '12px' }}>Generating deliverable...</Text>
                      </DeliverableLoadingContainer>
                    ) : (
                      <>
                        <DeliverableDetailsHeader className="deliverable-details-header">
                          <DeliverableDetailsTitle>
                            {selectedDeliverable.data?.metadata?.name}{' '}
                            {selectedDeliverable.data?.metadata?.isApproved ? <HiBadgeCheck /> : ''}
                          </DeliverableDetailsTitle>
                          <DeliverableDetailsDate>
                            Last Updated{' '}
                            {new Date(selectedDeliverable.data?.metadata?.updatedAt).toLocaleDateString('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric',
                            })}
                          </DeliverableDetailsDate>
                        </DeliverableDetailsHeader>
                        <DeliverableDetailsBody className="deliverable-details-body">
                          {parsedContents.textMarkdown && (
                            <DeliverableDetailsLongAnswer className="deliverable-details-long-answer">
                              <StreamingMarkdown content={longAnswer} />
                            </DeliverableDetailsLongAnswer>
                          )}

                          {parsedContents.tableString && (
                            <TableContainer className="table-container">
                              <ResultTable
                                tableString={parsedContents.tableString}
                                initialVisibleColumns={[
                                  'drug_name',
                                  'sponsor',
                                  'nct_id',
                                  'trial_name',
                                  'trial_status',
                                  'efficacy_data',
                                  'safety_data',
                                ]}
                                limitTableWidth={true}
                              />
                            </TableContainer>
                          )}
                        </DeliverableDetailsBody>
                      </>
                    )}
                  </DeliverableDetailsWrapper>

                  <DeliverableDetailsButtonGroup className="deliverable-details-button-group">
                    <Button
                      intent="success"
                      icon="share"
                      text="Share"
                      onClick={() => handleCopyShareLink(selectedDeliverableId)}
                      disabled={!selectedDeliverable.data.metadata.isApproved}
                    />
                    {/* <Button
                      intent="success"
                      icon="download"
                      text="Download"
                      onClick={handleDownload}
                      disabled={!selectedDeliverableId || longAnswerChanged}
                    /> */}
                    <Button
                      intent="success"
                      icon="edit"
                      text="Edit"
                      onClick={() => navigate(`/ai-agents/deliverables/${selectedDeliverableId}/edit`)}
                      disabled={!selectedDeliverableId || !selectedDeliverable.data.metadata.isApproved}
                    />
                    <Button
                      intent="success"
                      icon="refresh"
                      text="Refresh"
                      onClick={() => handleRefresh()}
                      disabled={!selectedDeliverableId || !selectedDeliverable.data.metadata.isApproved}
                    />

                    <Button
                      intent="success"
                      icon="annotation"
                      text="Rename"
                      onClick={() => setIsRenameDialogOpen(true)}
                      disabled={!selectedDeliverable.data.metadata.isApproved}
                    />
                    <Dialog
                      isOpen={isRenameDialogOpen}
                      onClose={() => {
                        setNameInput('');
                        setIsRenameDialogOpen(false);
                      }}
                    >
                      <DialogBody>
                        <h3>Rename Deliverable</h3>
                        <InputGroup
                          value={nameInput}
                          onChange={(e) => setNameInput(e.target.value)}
                          placeholder="Enter a new name"
                        />
                      </DialogBody>
                      <DeleteDialogFooter className="delete-deliverable-dialog-footer">
                        <Button
                          text="Cancel"
                          onClick={() => {
                            setNameInput('');
                            setIsRenameDialogOpen(false);
                          }}
                        />
                        <Button
                          intent="primary"
                          icon="floppy-disk"
                          text="Rename"
                          onClick={() => {
                            handleRename();
                            setIsRenameDialogOpen(false);
                          }}
                        />
                      </DeleteDialogFooter>
                    </Dialog>

                    <Button
                      intent="danger"
                      icon="trash"
                      text="Delete"
                      onClick={() => setIsDeleteDialogOpen(true)}
                      disabled={!selectedDeliverable.data.metadata.isApproved}
                    />
                    <Dialog isOpen={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                      <DialogBody>
                        <h3>Delete Deliverable</h3>
                        <Text>Are you sure you want to delete this deliverable?</Text>
                      </DialogBody>
                      <DeleteDialogFooter className="delete-deliverable-dialog-footer">
                        <Button text="Cancel" onClick={() => setIsDeleteDialogOpen(false)} />
                        <Button
                          intent="primary"
                          icon="floppy-disk"
                          text="Delete"
                          onClick={() => {
                            handleDelete();
                            setIsDeleteDialogOpen(false);
                          }}
                        />
                      </DeleteDialogFooter>
                    </Dialog>
                  </DeliverableDetailsButtonGroup>
                </DeliverableDetailsContainer>
              )}
            </DeliverableDetails>
          </DeliverablesContainer>
        )}
      </PageBody>
    </ViewContainer>
  );
};

export default AIAgentsRepositoryView;
