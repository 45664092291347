import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { showToast } from '../Util/Toaster.js';
import { snakeToCamel } from '../Util/CaseConvert.js';

import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogFooter,
  TextArea,
  FormGroup,
  Tooltip,
  Divider,
} from '@blueprintjs/core';
import { AiTwotoneFileText, AiTwotoneFilePdf } from 'react-icons/ai';

import NetworkSettings from '../Hooks/NetworkSettings';

const API_URL = NetworkSettings.SERVER_URL;

const DetailsTextArea = styled(TextArea)`
  flex-grow: 1;

  width: 100%;
  min-height: 80px;
`;

const SourcesButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
`;

const SourcesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

const SourceListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .bp5-label {
    margin: 0;
  }
`;

const SourceListItemLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const SourcesPanelBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SourcesPanelContainer = styled.div`
  padding: 0px 36px 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: 600px;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const SourcesPanelHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SourcesPanelTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: var(--sea-green);
  margin: 0;
`;

const DualButtonDialogFooter = styled(DialogFooter)`
  & .bp5-dialog-footer-main-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
`;

// ------------------------------------------------------------------------------------------------

const SourcesPanel = ({ researchSessionID }) => {
  const [isRecommendDialogOpen, setIsRecommendDialogOpen] = useState(false);
  const [isPullDialogOpen, setIsPullDialogOpen] = useState(false);

  // All source options for the research session
  const [sourceOptions, setSourceOptions] = useState([]);
  // Selected sources to attach to the research session
  const [selectedSources, setSelectedSources] = useState([]);
  // All user files from the knowledge repository
  const [userFiles, setUserFiles] = useState([]);
  // Selected user files to attach to the selected sources
  const [selectedUserFiles, setSelectedUserFiles] = useState([]);

  const [documentRecommedationDetails, setDocumentRecommedationDetails] = useState('');
  const [documentRecommedationPreferences, setDocumentRecommedationPreferences] = useState({
    fullSourceDocumentsOnly: false,
    summariesAllowed: false,
  });

  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const fileInputRef = useRef(null);

  // Load the session files from the research session on load
  useEffect(() => {
    const fetchSessionFiles = async () => {
      try {
        const response = await fetch(`${API_URL}/research-session/${researchSessionID}/files`, {
          credentials: 'include',
        });
        const data = await response.json();

        if (!response.ok) {
          throw new Error('Error fetching research session files');
        }

        const parsedData = snakeToCamel(data?.files);
        setSourceOptions(parsedData);
      } catch (error) {
        showToast({
          message: 'Error fetching research session files',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      }
    };
    fetchSessionFiles();
  }, []);

  // Fetch user files on load
  useEffect(() => {
    fetchUserFiles();
  }, []);

  // Remove a source from the list
  const handleDeleteSource = async (sourceId) => {
    try {
      const response = await fetch(`${API_URL}/research-session/${researchSessionID}/remove-file/${sourceId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error('Error removing source from research session');
      }
      if (data.status === 'success') {
        setSourceOptions(sourceOptions.filter((source) => source.s3ObjectKey !== sourceId));
        showToast({
          message: 'Source removed from research session',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      }
    } catch (error) {
      showToast({
        message: 'Error removing source from research session',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  // Handle the submit button click
  const handleRecommendDialogSubmit = () => {
    setIsRecommendDialogOpen(false);
  };

  // Handle the dialog close button click
  const handleRecommendDialogClose = () => {
    setDocumentRecommedationPreferences({
      fullSourceDocumentsOnly: false,
      summariesAllowed: false,
    });
    setIsRecommendDialogOpen(false);
  };

  // Handle the upload button click
  const handleUpload = async (event) => {
    const files = event.target.files;

    setIsUploading(true);
    setUploadStatus(null);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const formData = new FormData();
      formData.append('file', file);
      formData.append('researchSessionId', researchSessionID);
      try {
        const response = await axios.post(`${API_URL}/upload/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        });

        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const newSource = snakeToCamel(response.data?.result);
        setSourceOptions([...sourceOptions, newSource]);

        setUploadStatus('success');
        showToast({
          message: `Successfully uploaded ${file.name}`,
          intent: 'success',
          icon: 'tick',
        });
      } catch (error) {
        console.error('Upload failed:', error);
        setUploadStatus('error');

        const errorMessage = error.response?.data?.message || error.message || 'Unknown error occurred during upload';

        showToast({
          message: `Failed to upload ${file.name}: ${errorMessage}`,
          intent: 'danger',
          icon: 'error',
        });
      }
    }

    setIsUploading(false);
    setTimeout(() => setUploadStatus(null), 3000); // Clear status after 3 seconds
  };

  // Handle the pull from knowledge repository button click
  const fetchUserFiles = async () => {
    try {
      const response = await axios.get(`${API_URL}/user-files/`, { withCredentials: true });
      const parsedFiles = snakeToCamel(response.data?.files);
      setUserFiles(parsedFiles);
    } catch (error) {
      console.error('Failed to fetch user files:', error);
    }
  };

  // Handle the pull from knowledge repository button click
  const handlePullDialogOpen = () => {
    setIsPullDialogOpen(true);

    if (userFiles.length === 0) {
      fetchUserFiles();
    }
  };

  // Handle the pull from knowledge repository dialog close button click
  const handlePullDialogClose = () => {
    setIsPullDialogOpen(false);
  };

  // Handle the pull from knowledge repository dialog submit button click
  const handlePullDialogSubmit = async () => {
    try {
      // Show loading toast
      showToast({
        message: 'Adding files to session...',
        intent: 'primary',
        icon: 'upload',
      });

      // Make all API calls in parallel
      const results = await Promise.allSettled(
        selectedUserFiles.map((fileId) =>
          fetch(`${API_URL}/research-session/${researchSessionID}/add-file/${fileId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
          }),
        ),
      );

      // Count successes and failures
      const successes = results.filter((r) => r.status === 'fulfilled' && r.value.ok).length;
      const failures = selectedUserFiles.length - successes;

      // Update sourceOptions with newly added files
      const addedFiles = userFiles.filter((file) => selectedUserFiles.includes(file.s3ObjectKey));
      setSourceOptions((prev) => [...prev, ...addedFiles]);

      // Show result toast
      showToast({
        message: `Added ${successes} ${successes > 1 ? 'files' : 'file'}${failures > 0 ? `, ${failures} failed` : ''}`,
        intent: failures > 0 ? 'warning' : 'success',
        icon: failures > 0 ? 'warning-sign' : 'tick',
      });

      setSelectedUserFiles([]); // Clear selection
      setIsPullDialogOpen(false);
    } catch (error) {
      console.error('Error adding files to session:', error);
      showToast({
        message: 'Failed to add files to session',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  // Select a user file from knowledge repository
  const handleUserFileSelect = (fileId, isSelected) => {
    if (isSelected) {
      setSelectedUserFiles([...selectedUserFiles, fileId]);
    } else {
      setSelectedUserFiles(selectedUserFiles.filter((id) => id !== fileId));
    }
  };

  return (
    <SourcesPanelContainer className="sources-panel-container">
      <SourcesPanelHeader className="sources-panel-header">
        <SourcesButtonContainer className="sources-button-container">
          <Button
            intent="primary"
            icon="upload"
            text={isUploading ? 'Uploading...' : 'Upload to knowledge repository and session'}
            onClick={() => fileInputRef.current.click()}
            disabled={isUploading}
          />
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleUpload} multiple />

          <Button
            intent="primary"
            icon="bring-data"
            text="Pull from knowledge repository"
            onClick={() => handlePullDialogOpen()}
          />
          <Dialog
            title="Pull from knowledge repository"
            icon="bring-data"
            isOpen={isPullDialogOpen}
            onClose={handlePullDialogClose}
          >
            <DialogBody>
              {userFiles.map((file) => (
                <SourceListItem key={file.s3ObjectKey} className="source-list-item">
                  <Checkbox
                    key={file.s3ObjectKey}
                    label={file.originalFilename || file.fileName}
                    checked={
                      selectedUserFiles.includes(file.s3ObjectKey) ||
                      sourceOptions.some((source) => source.s3ObjectKey === file.s3ObjectKey) ||
                      selectedSources.includes(file.s3ObjectKey)
                    }
                    onChange={(e) => handleUserFileSelect(file.s3ObjectKey, e.target.checked)}
                    disabled={
                      sourceOptions.some((source) => source.s3ObjectKey === file.s3ObjectKey) ||
                      selectedSources.includes(file.s3ObjectKey)
                    }
                  />
                </SourceListItem>
              ))}
            </DialogBody>
            <DualButtonDialogFooter>
              <Button text="Cancel" onClick={handlePullDialogClose} />
              <Button intent="primary" text="Submit" onClick={handlePullDialogSubmit} />
            </DualButtonDialogFooter>
          </Dialog>

          <Tooltip content="Coming soon!" position="top">
            <Button
              intent="primary"
              icon="clean"
              text="Have Sleuth recommend source docs"
              onClick={() => setIsRecommendDialogOpen(true)}
              disabled
            />
          </Tooltip>
          <Dialog
            title="Have Sleuth recommend source docs"
            icon="clean"
            isOpen={isRecommendDialogOpen}
            onClose={handleRecommendDialogClose}
          >
            <DialogBody>
              <FormGroup fill label={'Describe the types of documents you want'}>
                <DetailsTextArea
                  placeholder="E.g., Recent medical journal articles on atopic dermatitis treatments"
                  value={documentRecommedationDetails}
                  onChange={(e) => setDocumentRecommedationDetails(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleRecommendDialogSubmit();
                    }
                  }}
                />
              </FormGroup>

              <FormGroup fill label={'Document preferences:'}>
                <Checkbox
                  label="Full source documents only"
                  checked={documentRecommedationPreferences.fullSourceDocumentsOnly}
                  onChange={(e) =>
                    setDocumentRecommedationPreferences({
                      ...documentRecommedationPreferences,
                      fullSourceDocumentsOnly: e.target.checked,
                    })
                  }
                />
                <Checkbox
                  label="Summaries allowed"
                  checked={documentRecommedationPreferences.summariesAllowed}
                  onChange={(e) =>
                    setDocumentRecommedationPreferences({
                      ...documentRecommedationPreferences,
                      summariesAllowed: e.target.checked,
                    })
                  }
                />
              </FormGroup>
            </DialogBody>
            <DualButtonDialogFooter>
              <Button text="Cancel" onClick={handleRecommendDialogClose} />
              <Button intent="primary" text="Submit" onClick={handleRecommendDialogSubmit} />
            </DualButtonDialogFooter>
          </Dialog>
        </SourcesButtonContainer>
      </SourcesPanelHeader>

      <Divider />

      <SourcesPanelBody className="sources-panel-body">
        <SourcesPanelTitle className="sources-panel-title">Sources</SourcesPanelTitle>

        {sourceOptions.length > 0 ? (
          <SourcesListContainer className="sources-list-container">
            {sourceOptions.map((source) => (
              <SourceListItem key={source.s3ObjectKey} className="source-list-item">
                <SourceListItemLabel key={source.s3ObjectKey}>
                  {(source.originalFilename || source.fileName).toLowerCase().endsWith('.pdf') ? (
                    <AiTwotoneFilePdf />
                  ) : (
                    <AiTwotoneFileText />
                  )}
                  {source.originalFilename || source.fileName}
                </SourceListItemLabel>
                <Button
                  minimal
                  intent="danger"
                  icon="trash"
                  size="small"
                  onClick={() => handleDeleteSource(source.s3ObjectKey)}
                />
              </SourceListItem>
            ))}
          </SourcesListContainer>
        ) : (
          <SourcesListContainer className="sources-list-container">
            <p>No sources added</p>
          </SourcesListContainer>
        )}
      </SourcesPanelBody>
    </SourcesPanelContainer>
  );
};

export default SourcesPanel;
