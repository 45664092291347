import React, { createContext, useState, useEffect } from 'react';
import NetworkSettings from '../Hooks/NetworkSettings.js';
import Cookies from 'js-cookie';
import { snakeToCamel } from '../Util/CaseConvert.js';

// API Endpoint
const isTesting = process.env.REACT_APP_USE_MOCK_AUTH === 'true';
const LOGIN_API_URL = isTesting ? '/' : NetworkSettings.SERVER_URL + '/login-workos';
const LOGOUT_API_URL = isTesting ? '/login' : NetworkSettings.SERVER_URL + '/logout-workos';

// Context for storing logged in user data.
const UserContext = createContext({});

const UserContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Check for auth cookie
  useEffect(() => {
    setIsLoading(true);

    let sessionCookie;
    // Try both methods of getting the cookie
    const cookieWithoutDomain = Cookies.get('wos-session');
    const cookieWithDomain = Cookies.get('wos-session', { domain: '.sleuthinsights.com' });

    const orgCookie = Cookies.get('wos-org-id');

    sessionCookie = cookieWithDomain || cookieWithoutDomain;

    if (sessionCookie && orgCookie) {
      try {
        // Preprocess the cookie string
        const sessionJSON = parseSessionCookie(sessionCookie);
        // Set authentication state based on the parsed data
        if (sessionJSON.access_token && sessionJSON.refresh_token && sessionJSON.user) {
          setIsAuthenticated(true);
          setUser(snakeToCamel(sessionJSON.user));
          setSession(snakeToCamel(sessionJSON));
          setOrganizationId(orgCookie);
        }
      } catch (error) {
        console.error('Error parsing session cookie:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  // Custom cookie parsing function
  const parseSessionCookie = (cookie) => {
    const processedCookie = cookie
      .replace(/^"/, '') // Remove leading double quote if present
      .replace(/"$/, '') // Remove trailing double quote if present
      .replace(/'/g, '"') // Replace single quotes with double quotes
      .replace(/\054/g, ',') // Replace \054 with commas
      .replace(/None/g, 'null') // Replace None with null
      .replace(/True/g, 'true') // Replace True with true
      .replace(/False/g, 'false'); // Replace False with false

    const parseCustomJSON = (str) => {
      const result = {};
      const regex = /"([^"]+)":\s*("[^"]*"|null|true|false|\{[^}]*\})/g;
      let match;
      while ((match = regex.exec(str)) !== null) {
        const key = match[1];
        let value = match[2];
        if (value.startsWith('"') && value.endsWith('"')) {
          value = value.slice(1, -1);
        } else if (value === 'null') {
          value = null;
        } else if (value === 'true') {
          value = true;
        } else if (value === 'false') {
          value = false;
        } else if (value.startsWith('{')) {
          value = parseCustomJSON(value.slice(1, -1));
        }
        result[key] = value;
      }
      return result;
    };
    return parseCustomJSON(processedCookie);
  };

  // Login
  const login = async () => {
    const tosAccepted = Cookies.get('tos-accepted');
    const ppAccepted = Cookies.get('pp-accepted');

    if (tosAccepted && ppAccepted) {
      window.location.href = LOGIN_API_URL;
    } else {
      // Redirect to login page if policies are not accepted
      window.location.href = '/login';
    }
  };

  const logout = () => {
    // Clear cookies
    Cookies.remove('wos-session');
    Cookies.remove('wos-session', { domain: '.sleuthinsights.com' });
    Cookies.remove('tos-accepted');
    Cookies.remove('pp-accepted');
    Cookies.remove('wos-org-id');

    // Update local state
    setIsAuthenticated(false);
    setUser(null);
    setSession(null);
    setOrganizationId(null);
    setIsLoading(false);

    window.location.href = LOGOUT_API_URL;
  };

  return (
    <UserContext.Provider value={{ isAuthenticated, user, login, logout, isLoading, session, organizationId }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
