import { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Colors,
  Dialog,
  DialogBody,
  DialogFooter,
  Divider,
  FormGroup,
  InputGroup,
  Icon,
  Spinner,
  Popover,
  Menu,
  MenuItem,
} from '@blueprintjs/core';

import NetworkSettings from '../Hooks/NetworkSettings';
import { snakeToCamel } from '../Util/CaseConvert.js';
import { showToast } from '../Util/Toaster.js';

const API_URL = NetworkSettings.SERVER_URL;

const ButtonContainer = styled.div`
  display: flex;
`;

const ItemLabel = styled.p`
  margin: 0;
  font-size: 1rem;
  color: ${Colors.DARK_GRAY5};

  font-weight: bold;
`;

const ItemValue = styled.p`
  margin: 0;
  font-size: 1rem;
  color: ${Colors.DARK_GRAY5};
`;

const MoreButton = styled(Button)`
  margin-left: auto;
  z-index: 1000;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 24px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px 24px 100px;
`;

const WorkspaceCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  border: 1px solid #ccc;
  padding: 12px;
  border-radius: 4px;
  background-color: ${Colors.WHITE};

  cursor: pointer;
  width: 300px;
  height: 150px;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--mint);
  }

  position: relative;
  z-index: 1;
`;

const WorkspaceCardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const WorkspaceCardBodyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
`;

const WorkspaceCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
`;

const WorkspaceCardHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const WorkspaceCardTitle = styled.h3`
  font-size: 1.2rem;
  color: #3e642c;
  margin: 0;
`;

const WorkspaceList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

// ---------------------------------------------------------------------------------------------------------------------

const ResearchWorkspaceListView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [workspaceList, setWorkspaceList] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');

  const [openPopoverId, setOpenPopoverId] = useState(null);

  const navigate = useNavigate();

  // Fetch the workspace list
  const fetchWorkspaceList = async () => {
    try {
      const response = await fetch(`${API_URL}/get-research-sessions/`, { credentials: 'include' });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const parsedData = snakeToCamel(data);

      if (parsedData?.status === 'success' && parsedData?.data?.researchSessions?.length > 0) {
        setWorkspaceList(parsedData.data.researchSessions);
      } else {
        setWorkspaceList([]);
      }
    } catch (error) {
      console.error('Error fetching workspaces:', error);
      setError(error);
      setWorkspaceList([]);
    }
  };

  // Fetch the workspace list on load
  useEffect(() => {
    fetchWorkspaceList();
  }, []);

  // Create a new research session
  const handleCreateNewSession = () => {
    const createNewSession = async () => {
      if (!workspaceName) {
        showToast({
          message: 'Please enter a name for the research session',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        return;
      }

      setIsLoading(true);

      try {
        const response = await fetch(`${API_URL}/create-research-session/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            researchSessionName: workspaceName,
          }),
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 'success') {
          // Immediately add the new workspace to the list for immediate feedback
          const newWorkspace = {
            sessionId: data.data,
            name: workspaceName,
          };

          setWorkspaceList((prevList) => [...prevList, newWorkspace]);

          showToast({
            message: 'Research session created successfully',
            intent: 'success',
            icon: 'tick',
            title: 'Elementary!',
          });

          fetchWorkspaceList();
        }

        setIsLoading(false);
        setIsDialogOpen(false);
        setWorkspaceName('');
      } catch (error) {
        console.error('Error creating research session:', error);
        setError(error);
        showToast({
          message: 'Failed to create research session',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        setIsLoading(false);
      }
    };

    createNewSession();
  };

  // Navigate to the research session view
  const handleWorkspaceClick = (workspaceId) => {
    navigate(`/research/${workspaceId}`);
  };

  // Delete a research session
  const handleDeleteSession = async (sessionId) => {
    try {
      const response = await fetch(`${API_URL}/delete-research-session/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ research_session_id: sessionId }),
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === 'success') {
        showToast({
          message: 'Research session deleted successfully',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
        setWorkspaceList((prevList) => prevList.filter((workspace) => workspace.sessionId !== sessionId));
      }
    } catch (error) {
      console.error('Error deleting research session:', error);
      setError(error);
      showToast({
        message: 'Failed to delete research session',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  // Handle the popover toggle
  const handlePopoverToggle = (e, workspaceId) => {
    e.stopPropagation();
    setOpenPopoverId(openPopoverId === workspaceId ? null : workspaceId);
  };

  // Return the view in different states
  if (isLoading) return <Spinner />;

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Research Workspace</PageTitle>
        <PageSubtitle className="page-subtitle">
          Research Workspace is a sandbox environment for you to compile your own notes and specified sources with our
          wider database into detailed reports.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        <ButtonContainer className="button-container">
          <Button intent="primary" icon="plus" onClick={() => setIsDialogOpen(true)}>
            Create new
          </Button>
          <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} title="Create new research session">
            <DialogBody>
              <FormGroup label="Name" labelInfo="(required)">
                <InputGroup
                  large
                  placeholder="Enter name"
                  value={workspaceName}
                  onChange={(e) => setWorkspaceName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleCreateNewSession();
                    }
                  }}
                />
              </FormGroup>
            </DialogBody>
            <DialogFooter>
              <Button intent="primary" onClick={() => handleCreateNewSession()}>
                Create
              </Button>
            </DialogFooter>
          </Dialog>
        </ButtonContainer>

        {workspaceList.length > 0 && <Divider />}

        {workspaceList.length > 0 && (
          <WorkspaceList className="workspace-list">
            {workspaceList.map((workspace) => (
              <WorkspaceCard key={workspace?.sessionId} onClick={() => handleWorkspaceClick(workspace?.sessionId)}>
                <WorkspaceCardHeader className="workspace-card-header">
                  <WorkspaceCardHeaderLeft className="workspace-card-header-left">
                    <Icon icon="book" />
                    <WorkspaceCardTitle>{workspace?.name}</WorkspaceCardTitle>
                  </WorkspaceCardHeaderLeft>

                  <Popover
                    isOpen={openPopoverId === workspace?.sessionId}
                    content={
                      <Menu>
                        <MenuItem
                          intent="danger"
                          icon="trash"
                          text={'Delete'}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteSession(workspace?.sessionId);
                            setOpenPopoverId(null); // Close popover after action
                          }}
                        />
                      </Menu>
                    }
                    position="top"
                    minimal={false}
                    usePortal={true}
                    enforceFocus={false}
                    autoFocus={false}
                    portalContainer={document.body}
                    onClose={() => setOpenPopoverId(null)}
                  >
                    <MoreButton
                      minimal
                      alignText="left"
                      rightIcon="more"
                      onClick={(e) => handlePopoverToggle(e, workspace?.sessionId)}
                    />
                  </Popover>
                </WorkspaceCardHeader>
                <WorkspaceCardBody className="workspace-card-body">
                  <WorkspaceCardBodyItem
                    className="workspace-card-body-item"
                    onClick={() => handleWorkspaceClick(workspace?.sessionId)}
                  >
                    <ItemLabel>Last Updated</ItemLabel>
                    <ItemValue>
                      {workspace?.updatedAt
                        ? new Date(workspace.updatedAt).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          })
                        : 'N/A'}
                    </ItemValue>
                  </WorkspaceCardBodyItem>

                  <WorkspaceCardBodyItem>
                    <ItemLabel>Created</ItemLabel>
                    <ItemValue>
                      {workspace?.createdAt
                        ? new Date(workspace.createdAt).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          })
                        : 'N/A'}
                    </ItemValue>
                  </WorkspaceCardBodyItem>
                </WorkspaceCardBody>
              </WorkspaceCard>
            ))}
          </WorkspaceList>
        )}
      </PageBody>
    </ViewContainer>
  );
};

export default ResearchWorkspaceListView;
