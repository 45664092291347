import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Colors, Tabs, Tab, Spinner, Tooltip } from '@blueprintjs/core';
import { PiPaperPlaneTiltDuotone, PiUserDuotone } from 'react-icons/pi';
import { FaUserAstronaut } from 'react-icons/fa6';

import NetworkSettings from '../Hooks/NetworkSettings';
import { showToast } from '../Util/Toaster.js';
import { snakeToCamel } from '../Util/CaseConvert.js';
import StreamingMarkdown from '../Components/StreamingMarkdown';

import NotesPanel from '../Components/NotesPanel.js';
import DraftPanel from '../Components/DraftPanel.js';
import SourcesPanel from '../Components/SourcesPanel.js';

const API_URL = NetworkSettings.SERVER_URL;

const ChatBody = styled.div`
  flex-grow: 1;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 6px;
`;

const ChatInput = styled.textarea`
  flex-grow: 1;
  padding: 8px 40px 12px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 0.9rem;
  resize: none;
  height: ${(props) => (props.value.includes('\n') ? 'auto' : '40px')};
  min-height: 40px;
  line-height: 1.4;
  overflow: ${(props) => (props.value.includes('\n') ? 'auto' : 'hidden')};
  vertical-align: top;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ChatInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 18px;
`;

const ChatInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ChatMainBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;

  height: 600px;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const IconButton = styled(Button)`
  transition: ease-in-out 0.2s;
  cursor: pointer;

  margin: 0 !important;
  padding: 0 !important;
  width: 16px !important;
  height: 16px !important;

  .bp5-icon {
    width: 12px !important;
    height: 12px !important;
    color: ${Colors.GRAY4} !important;

    svg {
      width: 12px !important;
      height: 12px !important;
      fill: ${(props) => props.color || Colors.GRAY4} !important;
    }
  }
`;

const LeftSidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;

  height: 600px;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const LeftSidebarContainer = styled.div`
  transition: ease-in-out 0.2s;
  background-color: ${Colors.WHITE};
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const LeftSidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 6px;
`;

const MainContentContainer = styled.div`
  transition: ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;

  background-color: ${Colors.WHITE};
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const MainTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .bp5-tab-list {
    background-color: ${Colors.LIGHT_GRAY4};
    padding: 6px 12px;
    column-gap: 30px;
    border-radius: 4px 4px 0 0;
  }

  & .bp5-tab-panel {
    margin-top: 8px;
  }
`;

const Message = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.$isUser ? 'row' : 'row-reverse')};
  gap: 12px;
  align-items: flex-end;
`;

const MessageContent = styled.div`
  background-color: ${(props) => (props.$isUser ? '#548B59' : '#f1f1f1')};
  color: ${(props) => (props.$isUser ? 'white' : 'black')};
  padding: ${(props) => (props.$isUser ? '16px 24px 24px 24px' : '16px 24px 12px 24px')};
  border-radius: 10px;
  display: inline-block;

  width: 96%;
  min-height: 60px;
  overflow-wrap: break-word;

  font-size: 0.9rem;

  /* Markdown Styles */
  p {
    margin-bottom: 0.5em;
  }

  ul,
  ol {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-left: 1.5em;
  }

  li {
    margin-bottom: 0.25em;
  }

  code {
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 4px;
  }

  pre {
    background-color: #f0f0f0;
    padding: 8px;
    border-radius: 4px;
    overflow-x: auto;
  }
`;

const MessageIcon = styled.div`
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  background-color: ${(props) => (props.$isUser ? 'var(--sleuth-green)' : 'var(--aureolin)')};
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageOptionsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px !important;
  align-items: center;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const RightSidebarContainer = styled.div`
  transition: ease-in-out 0.2s;
  background-color: ${Colors.WHITE};
  border-radius: 4px;
  border: 1px solid #ccc;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SendIconButton = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--sleuth-green);
  padding: 5px;

  &:hover {
    color: #2a4721;
  }
`;

const SessionChatThreadItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;

  cursor: pointer;
`;

const SidebarTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: var(--sea-green);
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ViewContainer = styled.div`
  padding: 12px 24px 100px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

// ---------------------------------------------------------------------------------------------------------------------

function ResearchWorkspaceView() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { researchSessionID } = useParams();

  const [leftPanelWidth, setLeftPanelWidth] = useState(20);
  const [rightPanelWidth, setRightPanelWidth] = useState(20);
  const [isLeftPanelVisible, setIsLeftPanelVisible] = useState(false);

  const [session, setSession] = useState(null);
  const [sessionMessages, setSessionMessages] = useState([]);
  const [sessionChatThreads, setSessionChatThreads] = useState([]);

  const [activeThreadId, setActiveThreadId] = useState(null);

  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [messageFeedback, setMessageFeedback] = useState({});

  const chatBodyRef = useRef(null);

  const [messages, setMessages] = useState([
    {
      content: "Welcome! I'm your biopharma AI research assistant. Let's explore the world of biopharma together.",
      isUser: false,
    },
  ]);

  // Fetch the session details on researchSessionID change
  useEffect(() => {
    const fetchSessionDetails = async () => {
      try {
        const response = await fetch(`${API_URL}/get-research-session/${researchSessionID}`, {
          credentials: 'include',
        });
        const data = await response.json();

        if (data?.status !== 'success') {
          throw new Error('Error fetching research session');
        }

        const parsedData = snakeToCamel(data?.data);

        if (parsedData.messages && parsedData.messages.length > 0) {
          setSessionMessages(parsedData.messages);
        }

        if (parsedData.session) {
          setSession(parsedData.session);
        }
      } catch (error) {
        console.error('Error fetching research session:', error);
        showToast({
          message: 'Error fetching research session',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      }
    };
    fetchSessionDetails();
  }, [researchSessionID]);

  // Fetch all session chat threads on researchSessionID change
  useEffect(() => {
    const fetchSessionChatThreads = async () => {
      const response = await fetch(`${API_URL}/research-session/${researchSessionID}/chat-threads/`, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data?.status !== 'success') {
        throw new Error('Error fetching session chat threads');
      }

      const parsedData = snakeToCamel(data?.data?.chat_threads);
      setSessionChatThreads(parsedData);
    };
    fetchSessionChatThreads();
  }, [researchSessionID]);

  const fetchSessionChatThreads = async () => {
    const response = await fetch(`${API_URL}/research-session/${researchSessionID}/chat-threads/`, {
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (data?.status !== 'success') {
      throw new Error('Error fetching session chat threads');
    }

    const parsedData = snakeToCamel(data?.data?.chat_threads);
    setSessionChatThreads(parsedData);
  };

  const maximizePanel = (panel) => {
    const setWidth = panel === 'left' ? setLeftPanelWidth : setRightPanelWidth;
    setWidth(40);
  };

  const minimizePanel = (panel) => {
    const setWidth = panel === 'left' ? setLeftPanelWidth : setRightPanelWidth;
    setWidth(20);
  };

  // Toggle left panel visibility
  const toggleLeftPanel = () => {
    setIsLeftPanelVisible((prev) => !prev);
  };

  // Handle start new thread
  const handleStartNewThread = async () => {
    setMessages([
      {
        content: "Welcome! I'm your biopharma AI research assistant. Let's explore the world of biopharma together.",
        isUser: false,
      },
    ]);
    setActiveThreadId(null);
    fetchSessionChatThreads();
  };

  // Handle select chat thread
  const handleSelectChatThread = async (chatThreadId) => {
    try {
      const response = await fetch(`${API_URL}/research-session/${researchSessionID}/chat-threads/${chatThreadId}`, {
        credentials: 'include',
      });
      const data = await response.json();

      if (data?.status === 'success' && data?.data?.messages) {
        // Filter out tool messages and messages with tool_use content
        const filteredMessages = data.data.messages.filter((message) => {
          // Filter out messages with type 'tool'
          if (message.type === 'tool') return false;

          // Filter out messages that contain tool_use in their content
          if (Array.isArray(message.content)) {
            const hasToolUse = message.content.some((item) => item.type === 'tool_use');
            return !hasToolUse;
          }

          return true;
        });

        // Transform messages to match your UI format
        const formattedMessages = filteredMessages.map((message) => ({
          content: message.content,
          isUser: message.type === 'human',
        }));

        setMessages(formattedMessages);
        setActiveThreadId(chatThreadId);
      }
    } catch (error) {
      console.error('Error fetching chat thread:', error);
    }
  };

  // Handle chat response
  const handleChatResponse = (response) => {
    if (response.data.status === 'success') {
      try {
        // If the response is a string, try to parse it
        const parsedContent = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

        const newMessage = {
          content: parsedContent.data.messages,
          isUser: false,
        };
        setMessages((prev) => [...prev, newMessage]);
      } catch (error) {
        console.error('Error parsing chat response:', error);
        // Fallback to raw content if parsing fails
        const newMessage = {
          content: response.data.data.messages,
          isUser: false,
        };
        setMessages((prev) => [...prev, newMessage]);
      }
    }
  };

  // Handle send message
  const handleSend = async () => {
    if (!inputMessage.trim()) return;

    const userMessage = {
      content: inputMessage,
      isUser: true,
    };
    setMessages((prev) => [...prev, userMessage]);
    setInputMessage('');
    setLoading(true);

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 60000);

      const response = await fetch(`${API_URL}/research-session/chat/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: inputMessage,
          researchSessionId: researchSessionID,
          threadId: activeThreadId,
        }),
        credentials: 'include',
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        accumulatedResponse += chunk;

        try {
          // Parse the accumulated response as JSON
          const parsedResponse = JSON.parse(accumulatedResponse);

          // Extract messages and threadId from the parsed response
          const responseData = parsedResponse.data;

          // Update messages with just the message content
          setMessages((prev) => {
            const newMessages = [...prev];
            if (newMessages[newMessages.length - 1].isUser) {
              newMessages.push({
                content: responseData.messages,
                isUser: false,
                citations: responseData.citations,
              });
            } else {
              newMessages[newMessages.length - 1].content = responseData.messages;
              newMessages[newMessages.length - 1].citations = responseData.citations;
            }
            return newMessages;
          });

          // Set the active thread ID
          if (responseData.threadId) {
            setActiveThreadId(responseData.threadId);
          }
        } catch (error) {
          // If JSON parsing fails, continue accumulating the response
          console.log('Accumulating response chunks...');
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      showToast({
        message: 'Failed to send message',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    } finally {
      setLoading(false);
      fetchSessionChatThreads();
    }
  };

  // Handle message copy
  const handleCopyMessage = (content, messageIndex) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopiedMessageId(messageIndex);
        setTimeout(() => {
          setCopiedMessageId(null);
        }, 1500);
      })
      .catch((err) => {
        console.error('Failed to copy message:', err);
      });
  };

  // Handle message feedback
  const handleFeedback = (messageIndex, feedbackType) => {
    setMessageFeedback((prev) => {
      const newFeedback = { ...prev };

      // If clicking the same button again, remove the feedback
      if (prev[messageIndex] === feedbackType) {
        delete newFeedback[messageIndex];
      } else {
        // Otherwise set the new feedback
        newFeedback[messageIndex] = feedbackType;
      }

      return newFeedback;
    });
  };

  if (!session) {
    return (
      <ViewContainer className="view-container">
        <Spinner />
      </ViewContainer>
    );
  }

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">{session?.name}</PageTitle>
      </PageHeader>

      <PageBody className="page-body">
        {isLeftPanelVisible && (
          <LeftSidebarContainer className="left-sidebar-container" style={{ width: `${leftPanelWidth}%` }}>
            <LeftSidebarHeader className="left-sidebar-header">
              {leftPanelWidth === 20 && (
                <Tooltip content="Maximize" placement="top">
                  <Button minimal icon="maximize" onClick={() => maximizePanel('left')} />
                </Tooltip>
              )}
              {leftPanelWidth === 40 && (
                <Tooltip content="Minimize" placement="top">
                  <Button minimal icon="minimize" onClick={() => minimizePanel('left')} />
                </Tooltip>
              )}

              <Tooltip content="Close" placement="top">
                <Button minimal icon="cross" onClick={() => toggleLeftPanel()} />
              </Tooltip>
            </LeftSidebarHeader>

            <LeftSidebarBody className="left-sidebar-body">
              <SidebarTitle className="sidebar-title">Chat Threads</SidebarTitle>
              {sessionChatThreads?.map((thread) => (
                <SessionChatThreadItem
                  key={thread?.chatThreadId}
                  onClick={() => handleSelectChatThread(thread?.chatThreadId)}
                >
                  {thread?.chatTitle}
                </SessionChatThreadItem>
              ))}
            </LeftSidebarBody>
          </LeftSidebarContainer>
        )}
        <MainContentContainer
          className="main-content-container"
          style={{
            width: `${100 - (isLeftPanelVisible ? leftPanelWidth : 0) - rightPanelWidth}%`,
          }}
        >
          <TabsContainer className="tabs-container">
            <MainTabs className="main-tabs">
              <Tab id="notes" title="Notes" panel={<NotesPanel />} icon="book" />
              <Tab id="reports" title="Draft" panel={<DraftPanel />} icon="document" />
              <Tab
                id="sources"
                title="Sources"
                panel={<SourcesPanel researchSessionID={researchSessionID} />}
                icon="projects"
              />
            </MainTabs>
          </TabsContainer>
        </MainContentContainer>
        <RightSidebarContainer className="right-sidebar-container" style={{ width: `${rightPanelWidth}%` }}>
          <ChatHeader className="chat-header">
            {rightPanelWidth === 20 && (
              <Tooltip content="Maximize" placement="top">
                <Button minimal icon="maximize" onClick={() => maximizePanel('right')} />
              </Tooltip>
            )}
            {rightPanelWidth === 40 && (
              <Tooltip content="Minimize" placement="top">
                <Button minimal icon="minimize" onClick={() => minimizePanel('right')} />
              </Tooltip>
            )}

            {!isLeftPanelVisible && (
              <Tooltip content="Open chat history" placement="top">
                <Button minimal icon="history" onClick={() => toggleLeftPanel()} />
              </Tooltip>
            )}

            <Tooltip content="Start new thread" placement="top">
              <Button minimal icon="chat" onClick={() => handleStartNewThread()} />
            </Tooltip>
          </ChatHeader>
          <ChatMainBody className="chat-main-body">
            <ChatBody ref={chatBodyRef} className="sleuthbot-chat-body">
              {messages?.map((message, index) => (
                <Message key={index} $isUser={!!message?.isUser} className="sleuthbot-message">
                  <MessageContent $isUser={!!message?.isUser} className="sleuthbot-message-content">
                    {message?.isUser ? (
                      message?.content
                    ) : (
                      <StreamingMarkdown
                        content={
                          typeof message?.content === 'string' ? message?.content : message?.content?.messages || ''
                        }
                      />
                    )}

                    {!message?.isUser && index !== 0 && (
                      <MessageOptionsContainer className="sleuthbot-message-options-container">
                        <IconButton
                          icon={copiedMessageId === index ? 'tick' : 'duplicate'}
                          small
                          minimal
                          onClick={() => handleCopyMessage(message?.content, index)}
                          className="sleuthbot-message-icon"
                        />
                        <IconButton
                          icon="thumbs-up"
                          small
                          minimal
                          onClick={() => handleFeedback(index, 'positive')}
                          color={messageFeedback[index] === 'positive' ? '#15B371' : Colors.GRAY4}
                          className="sleuthbot-message-icon"
                        />
                        <IconButton
                          icon="thumbs-down"
                          small
                          minimal
                          onClick={() => handleFeedback(index, 'negative')}
                          color={messageFeedback[index] === 'negative' ? '#CD4246' : Colors.GRAY4}
                          className="sleuthbot-message-icon"
                        />
                      </MessageOptionsContainer>
                    )}
                  </MessageContent>

                  <MessageIcon $isUser={!!message?.isUser} className="sleuthbot-message-icon">
                    {message?.isUser ? <PiUserDuotone size={20} /> : <FaUserAstronaut />}
                  </MessageIcon>
                </Message>
              ))}
              {loading && (
                <Message>
                  <MessageContent>
                    <Spinner size={16} />
                  </MessageContent>
                </Message>
              )}
            </ChatBody>

            <ChatInputContainer className="sleuthbot-chat-input-container">
              <ChatInputWrapper>
                <ChatInput
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                      e.preventDefault();
                      handleSend();
                    }
                  }}
                  placeholder="Type your message... "
                />
                <SendIconButton onClick={handleSend} type="button">
                  <PiPaperPlaneTiltDuotone size={20} />
                </SendIconButton>
              </ChatInputWrapper>
            </ChatInputContainer>
          </ChatMainBody>
        </RightSidebarContainer>
      </PageBody>
    </ViewContainer>
  );
}

export default ResearchWorkspaceView;
