import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import StreamingMarkdown from './StreamingMarkdown';
import { Button, Divider, Dialog, DialogBody, DialogFooter, FormGroup, InputGroup, Colors } from '@blueprintjs/core';
import { showToast } from '../Util/Toaster.js';
import { snakeToCamel, camelToSnake } from '../Util/CaseConvert.js';

import NetworkSettings from '../Hooks/NetworkSettings';

import {
  MDXEditor,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  CreateLink,
  InsertImage,
  InsertTable,
  ListsToggle,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  tablePlugin,
  imagePlugin,
  linkPlugin,
  linkDialogPlugin,
  markdownShortcutPlugin,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
} from '@mdxeditor/editor';

const API_URL = NetworkSettings.SERVER_URL;

const DraftButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

const DraftContent = styled.div`
  min-height: 300px;
`;

const DraftHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DraftPanelContainer = styled.div`
  padding: 0px 36px 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: 600px;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const DraftTimestamp = styled.p`
  font-size: 0.8rem;
  color: ${Colors.GRAY1};
`;

const DraftTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 18px;
  color: var(--sea-green);
`;

const StyledMDXEditor = styled(MDXEditor)`
  h2[id^='radix-:'] {
    display: none;
  }
`;

const DraftPanel = () => {
  const { researchSessionID } = useParams();
  const initialRenderRef = useRef(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isUserEdit, setIsUserEdit] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [draftName, setDraftName] = useState('');

  const [deliverable, setDeliverable] = useState(null);
  const [deliverableId, setDeliverableId] = useState(null);
  const [currentDraft, setCurrentDraft] = useState(null);
  const [currentDraftContent, setCurrentDraftContent] = useState('');
  const [initialDraftContent, setInitialDraftContent] = useState('');
  const draftContentChanged = isUserEdit && currentDraftContent !== initialDraftContent;

  // Reset the draft content when the research session changes
  useEffect(() => {
    setCurrentDraftContent('');
    setInitialDraftContent('');
    setDeliverable(null);
    setDeliverableId(null);
    setCurrentDraft(null);
  }, [researchSessionID]);

  // Fetch the current draft on load
  useEffect(() => {
    fetchDraft();
  }, []);

  // Update the current draft content and initial draft content when the current draft changes
  useEffect(() => {
    if (currentDraft) {
      setCurrentDraftContent(currentDraft);
      setInitialDraftContent(currentDraft);
    }
  }, [currentDraft]);

  // Fetch the current draft
  const fetchDraft = async () => {
    try {
      const response = await fetch(`${API_URL}/research-session/${researchSessionID}/deliverables`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const data = await response.json();
      const parsedData = snakeToCamel(data);

      if (!response.ok) {
        throw new Error('Error fetching deliverable');
      }

      if (parsedData.status === 'success' && parsedData.data.deliverables?.length > 0) {
        setDeliverable(parsedData.data.deliverables[0]);
        setCurrentDraft(parsedData.data.deliverables[0].components[0].body);
        setDeliverableId(parsedData.data.deliverables[0].deliverableId);
      }
    } catch (error) {
      console.error('Error fetching deliverable', error);
      showToast({
        message: 'Error fetching deliverable',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  // Handle the edit button click
  const handleEdit = async () => {
    if (isEditing && draftContentChanged) {
      const requestBody = {
        deliverable_id: deliverableId,
        name: deliverable?.name,
        components: [
          {
            component_id: deliverable?.components[0].componentId,
            type: 'text',
            body: currentDraftContent,
            position: deliverable?.components[0].position,
          },
        ],
      };

      try {
        const response = await fetch(`${API_URL}/save-deliverable/`, {
          method: 'POST',
          body: JSON.stringify(camelToSnake(requestBody)),
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok for save deliverable`);
        }
        const data = await response.json();

        showToast({
          message: `Deliverable saved successfully`,
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      } catch (error) {
        console.error('Error saving deliverable:', error);
        showToast({
          message: `Failed to save deliverable: ${error.message}`,
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      }
    }
    setIsEditing(!isEditing);
  };

  // Handle the send to deliverables button click
  const handleSaveToDeliverables = () => {
    console.log('Save to deliverables repository');
  };

  // Handle the create draft button click
  const handleCreateDraft = async () => {
    try {
      const response = await fetch(`${API_URL}/research-session/${researchSessionID}/deliverable/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: draftName,
          contents: 'New deliverable',
        }),
        credentials: 'include',
      });
      const data = await response.json();
      const parsedData = snakeToCamel(data);
      console.log('handleCreateDraft parsedData', parsedData);

      if (!response.ok) {
        throw new Error('Error creating draft');
      }

      setIsDialogOpen(false);
      fetchDraft();

      showToast({
        message: 'Deliverable created successfully',
        intent: 'success',
        icon: 'tick',
        title: 'Elementary!',
      });
    } catch (error) {
      showToast({
        message: 'Error creating draft',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  return (
    <DraftPanelContainer className="draft-panel-container">
      <DraftButtonContainer className="draft-button-container">
        {!deliverable && (
          <>
            <Button
              intent="primary"
              icon="add-to-artifact"
              text="Create deliverable"
              onClick={() => setIsDialogOpen(true)}
              disabled={deliverable}
            />
            <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} title="Create deliverable">
              <DialogBody>
                <FormGroup label="Name" labelInfo="(required)">
                  <InputGroup
                    large
                    placeholder="Enter name"
                    value={draftName}
                    onChange={(e) => setDraftName(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleCreateDraft();
                      }
                    }}
                  />
                </FormGroup>
              </DialogBody>
              <DialogFooter>
                <Button intent="primary" onClick={() => handleCreateDraft()}>
                  Create
                </Button>
              </DialogFooter>
            </Dialog>
          </>
        )}

        {currentDraft && (
          <Button
            intent="success"
            icon={isEditing ? (draftContentChanged ? 'floppy-disk' : 'eye-open') : 'edit'}
            text={isEditing ? (draftContentChanged ? 'Save' : 'View') : 'Edit'}
            onClick={handleEdit}
          />
        )}

        {/* <Button
          intent="success"
          icon="inheritance"
          text="Save to deliverables repository"
          onClick={handleSaveToDeliverables}
          disabled={!currentDraft}
        /> */}
      </DraftButtonContainer>

      <Divider />
      <DraftContent className="draft-content">
        {deliverable && (
          <DraftHeader className="draft-header">
            <DraftTitle className="draft-title">{deliverable?.name}</DraftTitle>
            <DraftTimestamp className="draft-timestamp">
              {' '}
              Last updated:&nbsp;
              {deliverable?.updatedAt
                ? new Date(deliverable.updatedAt).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                : 'N/A'}
            </DraftTimestamp>
          </DraftHeader>
        )}
        {isEditing ? (
          <StyledMDXEditor
            markdown={currentDraftContent || ''}
            onChange={(markdown) => {
              setCurrentDraftContent(markdown);
              if (initialRenderRef.current) {
                initialRenderRef.current = false;
              } else {
                setIsUserEdit(true);
              }
            }}
            spellCheck={false}
            trim={false}
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              thematicBreakPlugin(),
              imagePlugin({
                imageUploadHandler: undefined,
                // Simple validation for image URLs
                validateUrl: (url) => {
                  const isValidImageUrl = url.match(/^https?:\/\/.+\.(jpg|jpeg|png|gif|webp)$/i);
                  return isValidImageUrl !== null;
                },
              }),
              tablePlugin(),
              linkPlugin(),
              linkDialogPlugin(),
              markdownShortcutPlugin(),
              diffSourcePlugin({ diffMarkdown: initialDraftContent, viewMode: 'rich-text' }),
              toolbarPlugin({
                toolbarClassName: 'editor-toolbar',
                toolbarContents: () => (
                  <DiffSourceToggleWrapper>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles />
                    <BlockTypeSelect />
                    <CreateLink />
                    <InsertImage />
                    <InsertTable />
                    <ListsToggle />
                  </DiffSourceToggleWrapper>
                ),
              }),
            ]}
          />
        ) : (
          <StreamingMarkdown content={currentDraftContent} />
        )}
      </DraftContent>
    </DraftPanelContainer>
  );
};

export default DraftPanel;
