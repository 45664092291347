import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Divider, Popover, Menu, MenuItem } from '@blueprintjs/core';
import StreamingMarkdown from './StreamingMarkdown';

import NetworkSettings from '../Hooks/NetworkSettings';
import { snakeToCamel } from '../Util/CaseConvert.js';
import { showToast } from '../Util/Toaster.js';

import {
  MDXEditor,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  CreateLink,
  InsertImage,
  InsertTable,
  ListsToggle,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  tablePlugin,
  imagePlugin,
  linkPlugin,
  linkDialogPlugin,
  markdownShortcutPlugin,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
} from '@mdxeditor/editor';

const API_URL = NetworkSettings.SERVER_URL;

const BackButton = styled(Button)``;

const EditButton = styled(Button)``;

const MoreButton = styled(Button)``;

const NoteButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
`;

const NotesListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const NotesPanelContainer = styled.div`
  padding: 0px 36px 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  height: 600px;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const NoteItem = styled.div`
  width: 100%;
  padding: 12px 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const NoteItemDate = styled.div`
  font-size: 12px;
  color: #666;
`;

const NoteItemHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const NoteItemTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: var(--sea-green);
`;

const NoteItemContent = styled.div`
  color: #666;
`;

const NoteItemRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`;

const NotesDetailViewPanel = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const NoteDetailViewButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;

const NoteDetailViewContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NotesListTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: var(--sea-green);
  margin: 0;
`;

const OtherActionButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
`;

const StyledMDXEditor = styled(MDXEditor)`
  h2[id^='radix-:'] {
    display: none;
  }
`;

// ---------------------------------------------------------------------------------------------------------------------

// Partial content preview for notes
const getContentPreview = (content, maxLines = 5) => {
  if (!content) return '';
  const lines = content.trim().split('\n');
  const preview = lines.slice(0, maxLines).join('\n');
  return lines.length > maxLines ? preview + '\n...' : preview;
};

// Get the type of note
const getNoteType = (note) => {
  if (note.type === 'saved-response') {
    return 'Saved Response';
  }
  return 'Written Note';
};

// ---------------------------------------------------------------------------------------------------------------------

const NotesPanel = () => {
  const { researchSessionID } = useParams();
  const [notes, setNotes] = useState([]);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);

  const [openPopoverId, setOpenPopoverId] = useState(null);

  const initialRenderRef = useRef(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isUserEdit, setIsUserEdit] = useState(false);
  const [currentNoteContent, setCurrentNoteContent] = useState('');
  const [initialNoteContent, setInitialNoteContent] = useState('');
  const noteContentChanged = isUserEdit && currentNoteContent !== initialNoteContent;

  // Get all notes for the session
  useEffect(() => {
    const fetchNotes = async () => {
      const response = await fetch(`${API_URL}/research-session/${researchSessionID}/notes`, {
        credentials: 'include',
      });
      const data = await response.json();
      const parsedData = snakeToCamel(data?.data?.notes);
      // Sort notes by updatedAt in descending order
      const sortedNotes = [...parsedData].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setNotes(sortedNotes);
    };
    fetchNotes();
  }, [researchSessionID]);

  // Update the selected note when the selected note ID changes
  useEffect(() => {
    if (selectedNoteId) {
      setSelectedNote(notes.find((note) => note.noteId === selectedNoteId));
    }
  }, [selectedNoteId]);

  // Handle the create new note button click
  const handleCreateNewNote = async () => {
    try {
      const response = await fetch(`${API_URL}/research-session/${researchSessionID}/notes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          body: 'New note',
        }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data?.status === 'success') {
        setNotes((prevNotes) => {
          const updatedNotes = [...prevNotes, snakeToCamel(data.data)];
          return updatedNotes.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        });
      }
    } catch (error) {
      console.error('Error creating note: ', error);
      showToast({
        message: 'Failed to create note',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  // Handle the edit button click
  const handleEdit = async () => {
    if (isEditing && noteContentChanged) {
      try {
        const response = await fetch(`${API_URL}/notes/${selectedNoteId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ body: currentNoteContent }),
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data?.status === 'success') {
          const updatedNote = snakeToCamel(data.data);
          setSelectedNote((prevNote) => ({ ...prevNote, ...updatedNote }));
          setNotes((prevNotes) => {
            const updatedNotes = prevNotes.map((note) =>
              note.noteId === selectedNoteId ? { ...note, ...updatedNote } : note,
            );
            return updatedNotes.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          });
          setInitialNoteContent(currentNoteContent);
          setIsUserEdit(false);

          showToast({
            message: 'Note saved successfully',
            intent: 'success',
            icon: 'tick',
            title: 'Elementary!',
          });
        } else {
          throw new Error('Failed to save note');
        }
      } catch (error) {
        console.error('Error saving note: ', error);
        showToast({
          message: 'Failed to save note',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      }
    }
    setIsEditing(!isEditing);
  };

  // Handle the add to sources button click
  const handleAddToSources = () => {};

  // Delete a note
  const handleDeleteNote = async (noteId) => {
    try {
      const response = await fetch(`${API_URL}/notes/${noteId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.message === 'Note deleted successfully') {
        showToast({
          message: 'Note deleted successfully',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
        setNotes((prevList) => prevList.filter((note) => note.noteId !== noteId));
      }
    } catch (error) {
      console.error('Error deleting note:', error);
      showToast({
        message: 'Failed to delete note',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  // Handle the popover toggle
  const handlePopoverToggle = (e, noteId) => {
    e.stopPropagation();
    setOpenPopoverId(openPopoverId === noteId ? null : noteId);
  };

  return (
    <NotesPanelContainer className="notes-panel-container">
      {selectedNoteId && selectedNote ? (
        <NotesDetailViewPanel className="notes-detail-view-panel" $note={selectedNote}>
          <NoteDetailViewButtonContainer className="note-detail-view-button-container">
            <BackButton onClick={() => setSelectedNoteId(null)} icon="arrow-left" minimal>
              Back
            </BackButton>

            <OtherActionButtonsContainer className="other-action-buttons-container">
              <EditButton
                intent="success"
                icon={isEditing ? (noteContentChanged ? 'floppy-disk' : 'eye-open') : 'edit'}
                text={isEditing ? (noteContentChanged ? 'Save' : 'View') : 'Edit'}
                onClick={handleEdit}
                disabled={!selectedNoteId}
              />
              {/* <Button intent="success" icon="plus" text="Add to sources" onClick={handleAddToSources} /> */}
            </OtherActionButtonsContainer>
          </NoteDetailViewButtonContainer>

          <Divider />

          <NoteDetailViewContentContainer className="note-detail-view-content-container">
            <NoteItemContent>
              {isEditing ? (
                <StyledMDXEditor
                  markdown={selectedNote.body || ''}
                  onChange={(markdown) => {
                    setCurrentNoteContent(markdown);
                    if (initialRenderRef.current) {
                      initialRenderRef.current = false;
                    } else {
                      setIsUserEdit(true);
                    }
                  }}
                  spellCheck={false}
                  trim={false}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    imagePlugin({
                      imageUploadHandler: undefined,
                      // Simple validation for image URLs
                      validateUrl: (url) => {
                        const isValidImageUrl = url.match(/^https?:\/\/.+\.(jpg|jpeg|png|gif|webp)$/i);
                        return isValidImageUrl !== null;
                      },
                    }),
                    tablePlugin(),
                    linkPlugin(),
                    linkDialogPlugin(),
                    markdownShortcutPlugin(),
                    diffSourcePlugin({ diffMarkdown: initialNoteContent, viewMode: 'rich-text' }),
                    toolbarPlugin({
                      toolbarClassName: 'editor-toolbar',
                      toolbarContents: () => (
                        <DiffSourceToggleWrapper>
                          <UndoRedo />
                          <BoldItalicUnderlineToggles />
                          <BlockTypeSelect />
                          <CreateLink />
                          <InsertImage />
                          <InsertTable />
                          <ListsToggle />
                        </DiffSourceToggleWrapper>
                      ),
                    }),
                  ]}
                />
              ) : (
                <StreamingMarkdown content={selectedNote.body} />
              )}
            </NoteItemContent>
          </NoteDetailViewContentContainer>
        </NotesDetailViewPanel>
      ) : (
        <NotesListContainer className="notes-list-container">
          <NoteButtonContainer className="note-button-container">
            <Button intent="primary" icon="add" text="Add note" onClick={handleCreateNewNote} />
          </NoteButtonContainer>

          <Divider />

          <NotesListTitle>Notes</NotesListTitle>

          {notes?.length === 0 && <p>No notes added</p>}
          {notes?.map((note) => (
            <NoteItem
              className="note-item"
              key={note.noteId}
              $note={note}
              onClick={() => setSelectedNoteId(note.noteId)}
            >
              <NoteItemHeader className="note-item-header">
                <NoteItemTitle className="note-item-title">{getNoteType(note)}</NoteItemTitle>
                <NoteItemRight className="note-item-right">
                  <NoteItemDate className="note-item-date">
                    Last updated:&nbsp;
                    {note?.updatedAt
                      ? new Date(note.updatedAt).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                      : 'N/A'}
                  </NoteItemDate>

                  <Popover
                    isOpen={openPopoverId === note?.noteId}
                    content={
                      <Menu>
                        <MenuItem
                          intent="danger"
                          icon="trash"
                          text={'Delete'}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteNote(note?.noteId);
                            setOpenPopoverId(null); // Close popover after action
                          }}
                        />
                      </Menu>
                    }
                    position="top"
                    minimal={false}
                    usePortal={true}
                    enforceFocus={false}
                    autoFocus={false}
                    portalContainer={document.body}
                    onClose={() => setOpenPopoverId(null)}
                  >
                    <MoreButton
                      minimal
                      alignText="left"
                      rightIcon="more"
                      onClick={(e) => handlePopoverToggle(e, note?.noteId)}
                    />
                  </Popover>
                </NoteItemRight>
              </NoteItemHeader>
              <NoteItemContent className="note-item-content">
                <StreamingMarkdown content={getContentPreview(note.body)} />
              </NoteItemContent>
            </NoteItem>
          ))}
        </NotesListContainer>
      )}
    </NotesPanelContainer>
  );
};

export default NotesPanel;
